.start_session {
  background-color: #161616;
  border-radius: 14px;
  padding: 14px 26px 14px 22px;
  height: 56px;
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  border: 0;

  &:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: #2b2b2c;
    color: #fff;
  }

  img {
    margin-right: 8px;
  }
}

.participant_select {
  &.ant-select {
    min-height: 40px;
    border-radius: 8px;

    .ant-select-arrow {
      left: 12px;
    }

    .ant-select-selector {
      border: 1px solid #ccceda;
      padding: 0 10px 0 36px;

      .ant-select-selection-search-input {
        inset-inline-start: 0;
        height: 40px;
      }
    }
  }
}

.full_width_btn {
  &.ant-dropdown {
    .ant-dropdown-menu {
      padding: 8px 4px;
      .ant-dropdown-menu-item {
        padding: 0;
        margin-bottom: 4px;

        &:last-child {
          margin: 0;
        }

        button {
          width: 100%;
          justify-content: flex-start;
          padding: 8px 12px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #070707;
          border: 0;
          box-shadow: none;
          height: 36px;
          background-color: transparent;
          &:disabled{
            color: #70727D;
          }
        }
      }
    }
  }
}

.content_area {
  .session_title {
    padding: 0;
    .ant-card-body {
      width: 100%;
      padding: 12px 16px;
    }

    .mainTitle {
      justify-content: space-between;

      .session_head_left {
        display: flex;
        align-items: center;
        gap: 16px;

        h3 {
          color: #161616;
          font-size: 20px;
          font-weight: 600;
          line-height: 26px;
          margin: 0;
        }
      }
    }
  }
}

.split_screen.participants_card {
  .ant-radio-group {
    .ant-radio-button-wrapper {
      width: 182px;

      .participant_info {
        h6 {
          text-transform: none;
          margin-bottom: 0;
        }
      }

      .participant_check {
        margin-left: auto;
      }
    }
  }
}

.participants_card {
  .ant-radio-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 24px;

    .ant-radio-button-wrapper {
      height: auto;
      border: 1px solid #ecedf3;
      border-radius: 14px;
      padding: 16px;

      &.ant-radio-button-wrapper-checked {
        background: #161616;
        border: 1px solid #161616;

        .participant_info {
          h6 {
            color: #ffffff;
          }
          p {
            color: #d9dbe6;
          }
        }

        .participant_check {
          opacity: 1;
        }
      }

      &:before {
        display: none;
      }

      .ant-radio-button {
        + span,
        + span span {
          display: flex;
          justify-content: flex-start;
          width: 100%;
        }
      }

      .participant_avatar {
        .ant-avatar {
          width: 40px;
          height: 40px;
          border: 1px solid #e8e9f0;
          background-color: #f3f4f8;
          color: #161616;
          font-size: 11px;
          font-weight: 600;
          line-height: 16px;
          justify-content: center;
          align-items: center;
        }
      }

      .participant_info {
        padding-left: 10px;
        h6 {
          color: #070707;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          margin-bottom: 2px;
          text-transform: capitalize;
        }

        p {
          color: #4c4d51;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          margin: 0;
          text-transform: capitalize;
        }
      }

      .participant_check {
        margin-left: 10px;
        opacity: 0;

        img {
          display: block;
        }
      }
    }
  }
}

.participants_modal_wrapper {
  display: flex;
  gap: 8px;
  margin-top: 16px;
  flex-wrap: wrap;

  .participants_card_modal {
    border: 1px solid #ECEDF3;
    border-radius: 14px;

    .ant-card-body {
      padding: 16px 16px;
      border-radius: 14px;
      display: flex;
      align-items: flex-start;
    }
    .participant_avatar {
      .ant-avatar {
        width: 40px;
        height: 40px;
        border: 1px solid #e8e9f0;
        background-color: #f3f4f8;
        color: #161616;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
      }
    }

    .participant_info {
      padding-left: 10px;
      h6 {
        color: #070707;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        text-transform: capitalize;
      }

      p {
        color: #4C4D51 !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 20px !important;
        margin: 0;
      }
    }

    .participant_check {
      margin-left: 10px;

      button {
        width: 18px;
        height: 18px;
        margin-top: 2px;
        padding: 0;
        .ant-image{
          width: 12px !important;
          height: 12px !important;
          .ant-image-img{
            width: 12px !important;
            height: 12px !important;
          }
        }
      }

      img {
        display: block;
      }
    }
  }
}

.fp_layout_content {
  &.session_card {
    .cardContent {
      max-width: 766px;
      margin: 0 auto;
      margin-top: 76px;
      border: 0;
      padding: 0;
      border-radius: 0;
    }
  }
}

.select_individual_head {
  display: flex;
  margin-bottom: 24px;
  justify-content: space-between;
  align-items: flex-start;

  h4 {
    color: #161616;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 4px;

    span {
      color: #8558ea;
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
    }
  }
  p {
    color: #161616;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 0;
  }

  .individual_right {
    .icon_grey_btn {
      border-radius: 8px;
      height: 32px;
      padding: 7px 12px;
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.link_card {
  .ant-card-body {
    height: calc(100% - 88px);
  }

  .copy_link_content {
    height: 100%;
    position: relative;

    .ant-alert:not(.success_copy) {
      // position: absolute;
      // bottom: 0;
      // left: 0;
      margin-top: 12px;
      width: 100%;
      border: 0;
      background: #fef6e7;
      border-radius: 10px;
      padding: 16px 16px 19px;

      .anticon {
        margin-inline-end: 12px;

        svg {
          width: 26px;
          height: 26px;
          color: #eb9d06;
        }
      }

      .ant-alert-message {
        color: #070707;
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        margin-bottom: 4px;
      }

      .ant-alert-description {
        color: #161616;
        font-size: 14px;
        line-height: 20px;
        font-weight: 300;
      }
    }
    .session2_content{
      h2{
        font-size: 20px;
        font-weight: 600;
        color: #070707;
        line-height: 26px;
        margin-bottom: 8px;
      }
      p{
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        color: #161616;
      }
      ol {
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        color: #070707;
        padding-left: 15px;
      }
    }
  }
}

.steps_footer {
  border-top: 1px solid #ecedf3;
  padding: 24px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 88px;

  
}

.steps_btn_wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.sortable_row {
  display: flex;
}

$font-weight: 400;
$background-color: #fff;
$border-color: #efefef;
$text-color: #333;
$handle-color: rgba(0, 0, 0, 0.25);
$box-shadow-border: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05);
$box-shadow-common: 0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
$box-shadow: $box-shadow-border, $box-shadow-common;
$focused-outline-color: #4c9ffe;

@keyframes pop {
  0% {
    transform: scale(1);
    box-shadow: var(--box-shadow);
  }
  100% {
    transform: scale(var(--scale));
    box-shadow: var(--box-shadow-picked-up);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Wrapper {
  display: flex;
  box-sizing: border-box;
  transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0)
    scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1));
  transform-origin: 0 0;
  touch-action: manipulation;

  &.fadeIn {
    animation: fadeIn 500ms ease;
  }

  &.dragOverlay {
    --scale: 1.05;
    --box-shadow: $box-shadow;
    --box-shadow-picked-up: $box-shadow-border,
      -1px 0 15px 0 rgba(34, 33, 81, 0.01),
      0px 15px 15px 0 rgba(34, 33, 81, 0.25);
    z-index: 999;
  }
}

.Item {
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 18px 20px;
  background-color: $background-color;
  box-shadow: $box-shadow;
  outline: none;
  border-radius: calc(4px / var(--scale-x, 1));
  box-sizing: border-box;
  list-style: none;
  transform-origin: 50% 50%;

  -webkit-tap-highlight-color: transparent;

  color: $text-color;
  font-weight: $font-weight;
  font-size: 1rem;
  white-space: nowrap;

  transform: scale(var(--scale, 1));
  transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);

  &:focus-visible {
    box-shadow: 0 0px 4px 1px $focused-outline-color, $box-shadow;
  }

  &:not(.withHandle) {
    touch-action: manipulation;
    cursor: grab;
  }

  &.dragging:not(.dragOverlay) {
    opacity: var(--dragging-opacity, 0.5);
    z-index: 0;

    &:focus {
      box-shadow: $box-shadow;
    }
  }

  &.disabled {
    color: #999;
    background-color: #f1f1f1;
    &:focus {
      box-shadow: 0 0px 4px 1px rgba(0, 0, 0, 0.1), $box-shadow;
    }
    cursor: default;
  }

  &.dragOverlay {
    cursor: inherit;
    /* box-shadow: 0 0px 6px 2px $focused-outline-color; */
    animation: pop 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
    transform: scale(var(--scale));
    box-shadow: var(--box-shadow-picked-up);
    opacity: 1;
  }

  &.color:before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 100%;
    width: 3px;
    display: block;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: var(--color);
  }

  &:hover {
    .Remove {
      visibility: visible;
    }
  }
}

.Remove {
  visibility: hidden;
}

.Actions {
  display: flex;
  align-self: flex-start;
  margin-top: -12px;
  margin-left: auto;
  margin-bottom: -15px;
  margin-right: -10px;
}

$focused-outline-color: #4c9ffe;

.Action {
  display: flex;
  width: 12px;
  padding: 15px;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  touch-action: none;
  cursor: var(--cursor, pointer);
  border-radius: 5px;
  border: none;
  outline: none;
  appearance: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;

  @media (hover: hover) {
    &:hover {
      background-color: var(--action-background, rgba(0, 0, 0, 0.05));

      svg {
        fill: #6f7b88;
      }
    }
  }

  svg {
    flex: 0 0 auto;
    margin: auto;
    height: 100%;
    overflow: visible;
    fill: #919eab;
  }

  &:active {
    background-color: var(--background, rgba(0, 0, 0, 0.05));

    svg {
      fill: var(--fill, #788491);
    }
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0),
      0 0px 0px 2px $focused-outline-color;
  }
}

.session_card_top {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 0;
    color: #070707;
  }
}

.job_status_tag {
  .defaultTag {
    font-size: 16px;
    padding: 4px 12px;
    line-height: 24px;
  }
  .default_badge {
    width: 9px;
    height: 9px;
    border-radius: 2px;
  }
}

.ant-empty  {
  .ant-empty-image {
    margin-bottom: 24px;
  }
}



.ant-empty-description {
  h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 8px;
    color: #050622;
  }

  p {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #050622;
    margin-bottom: 0;
  }
}

.session_card {
  .ant-empty-footer {
    .icon_dark_btn {
      height: 56px;
    }
  }
}

.task_list {
  .tasks_section {
    h4 {
      font-size: 20px !important;
      line-height: 26px !important;
      font-weight: 600 !important;
      color: #161616;
      margin-bottom: 4px;
    }
    p {
      font-size: 16px !important;
      line-height: 24px !important;
      font-weight: 300 !important;
      color: #161616;
      margin-bottom: 24px;
    }
    .generate_list {
      width: 612px !important;
    }
    .user_profile {
      max-width: 612px;
      width: 100%;
      padding: 16px;
      border-radius: 16px;
      border: 1px solid #f3f4f8;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      h4 {
        font-size: 16px !important;
        line-height: 20px !important;
        font-weight: 600 !important;
        margin-bottom: 0px !important;
      }
      p {
        margin-bottom: 0px !important;
      }
      .ant-avatar {
        width: 40px;
        height: 40px;
      }
    }
  }
  .add_more {
    margin-bottom: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    border: 1px solid #f3f4f8;
    border-radius: 16px;
    padding: 16px;
    .addBook_wrapper {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 4px;
      gap: 4px;
      .book_name {
        width: 100%;
        .ant-form-item {
          margin-bottom: 0px;
          .ant-input-outlined {
            border: 1px solid #f7f8fa;
            background-color: #f7f8fa;
            font-weight: 300;
            font-size: 14px;
            height: 32px;
            line-height: 20px;
            font-weight: 300;
            color: #161616;
            padding-right: 35px !important;
            &::placeholder {
              color: #9396a6;
              font-weight: 300;
              font-size: 14px;
            }
            &:hover{
              background-color: #F3F4F8;
            }
          }
        }
      }
      .book_delete {
        height: 30px;
        position: absolute;
        right: 0;
        button {
          height: 100%;
          background-color: transparent;
          padding: 10px;
          box-shadow: unset;
          outline: unset;
          border: unset;
          svg {
            path {
              fill: #babbc1;
            }
          }
        }
      }
    }
    .addFieldButton {
      border: unset;
      padding: 0px;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      color: #161616;
      margin-top: 15px;
      box-shadow: unset;
      &:hover,
      &:focus,
      &:active{
        color: #161616;
        outline: unset;
        box-shadow: unset;
      }
    }
  }
}

.submission_unsuccessful {
  .inner_area_1 {
    text-align: center;
    img {
      margin-bottom: 24px;
    }
    h2 {
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
      color: #0f0f10;
      margin-bottom: 12px;
    }
    p {
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      color: #050622;
    }
  }
  .inner_area_2 {
    padding: 16px;
    border: 1px solid #ecedf3;
    border-radius: 12px;
    ul {
      padding-left: 16px;
      li {
        font-size: 14px;
        line-height: 20px;
        font-weight: 300;
        color: #161616;
      }
    }
    h4 {
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      color: #161616;
      margin-bottom: 8px;
    }
  }
}

.participant_check {
  opacity: 1;
  .ant-image {
    display: block !important;
    img {
      display: block;
    }
  }
}

.status_card_step2 {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 26px;

  .ant-btn {
    padding: 0;
    height: auto;
    border: 0;
    border-radius: 10px;
  }

  .ant-card {
    border: 0;
  }

  .ant-card-body {
    padding: 16px;
    border-radius: 10px;
    width: 280px;
  }

  .ant-card-meta-avatar {
    padding-inline-end: 12px;
  }

  .ant-card-meta-detail {
    text-align: left;

    .ant-card-meta-title {
      color: #070707;
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      margin-bottom: 4px !important;
    }

    .ant-card-meta-description {
      color: #070707;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
    }
  }
}

.step2_role {
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio_check {
  display: none;
}

.ant-radio-wrapper-checked {
  .radio_uncheck {
    display: none;
  }

  .radio_check {
    display: block;
  }
}

.ws_session_area {
  .empty_card {
    .empty_sessions_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
      .icon_dark_btn {
        height: 40px !important;
        .ant-image {
          width: auto !important;
          img {
            width: 12px;
          }
        }
      }
    }
  }
}

.fp_session_area {
  min-height: calc(100vh - 245px);

  .empty_card {
    .empty_sessions_btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
    }
  }
}

.session_completed {
  min-height: calc(100vh - 48px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 20px;
  .ant-card-body {
    h1 {
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
      margin-bottom: 24px;
    }
    h3 {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 12px;
      display: flex;
      gap: 10px;
      color: #050622;
      font-family: 'DM Sans';
      .total_roles {
        background-color: #070707;
        border-radius: 6px;
        height: 24px;
        width: 24px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }
    }
  }
  .roles_tag_wrapper {
    margin-bottom: 32px;
    .roles_tag {
      .ant-tag{
        padding: 4px 12px;
        border: unset;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        border-radius: 8px;
      }
      &:nth-child(1) {
        .ant-tag{
        background-color: #F1F0FD;
        color: #6661EA;
        }
      }
      &:nth-child(2) {
        .ant-tag{
        background-color: #E8FAFB;
        color: #047E82;
        }
      }
      &:nth-child(3) {
        .ant-tag{
        background-color: #FFF0FA;
        color: #CB2C8B;
        }
      }
      &:nth-child(4) {
        .ant-tag{
        background-color: #FFF6E5;
        color: #986707;
        }
      }
      &:nth-child(5) {
        .ant-tag{
        background-color: #EFFDF0;
        color: #098413;
        }
      }
      &:nth-child(6) {
        .ant-tag{
        background-color: #F2F6FF;
        color: #3C68DC;
        }
      }
      &:nth-child(7) {
        .ant-tag{
        background-color: #F6F3FF;
        color: #8558EA;
        }
      }
      &:nth-child(8) {
        .ant-tag{
        background-color: #FDEFEF;
        color: #D33333;
        }
      }
      &:nth-child(9) {
        .ant-tag{
        background-color: #FCF2FF;
        color: #A63CDC;
        }
      }
      &:nth-child(10) {
        .ant-tag{
        background-color: #FEF6E7;
        color: #A56110;
        }
      }
    }
  }
  .invite_sent {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #161616;
  }
}


.detail_option {
  display: flex;
  align-items: center;

  .user_name {
    margin-left: 8px;
    color: #070707;
    font-size: 14px;
    font-weight: 400;
  }

  .user_position {
    margin-left: 8px;
    border: 1px solid #EFF0F5;
    border-radius: 6px;
    padding: 2px 8px;
    color: #161616;
    font-weight: 300;
  }

  .participant_avatar {
    .ant-avatar {
      width: 24px;
      height: 24px;
      border: 1px solid #e8e9f0;
      background-color: #f3f4f8;
      color: #161616;
      font-size: 11px;
      font-weight: 600;
      line-height: 16px;
    }
  }
}

.participant_avatar{
  .ant-avatar-string{
    justify-content: center !important; 
  }
}

.select_category {
  &.ant-select {
    margin-top: 0px;
    min-height: 34px !important;
    height: 34px !important;
    background: #F3F4F8;
    border-radius: 6px;
    margin-left: 5px;

    .ant-select-selector {
      min-height: 34px !important;
      padding: 2px 6px;
      border-radius: 6px;
      border: 1px solid #fff !important;
      min-width: 140px;
    }

    .ant-select-arrow {
      svg {
        height: 9px;
        width: 9px;
      }
    }
  }
}

.link_team {
  display: flex;
  align-items: center;
  margin-top: 8px;

  .link_label {
    color: #161616;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    width: 80px;
    margin-right: 8px;
    text-transform: capitalize;
  }

  .copyLinkText {
    width: calc(100% - 88px);
  }
}

.job_title {
  font-size: 14px;
  color: #303030;
  line-height: 20px;
  font-weight: 300;
}

.team_tag {
  margin-left: 16px;
  border-radius: 8px;
  padding: 4px 12px;
  color: rgba(133, 88, 234, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  background-color: rgba(246, 243, 255, 1);
  border: 1px solid rgba(133, 88, 234, 0.4);
  text-transform: capitalize;
}

.ant-checkbox-disabled {
  .ant-checkbox-inner {
    background: #70727D;
    border-color: #fff;
    &::after{
      background: #70727D;
      border-color: #fff;
    }
  }
}