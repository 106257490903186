.content_area {
  background-color: #f3f4f8;
  padding: 24px;
  .fp_layout_title {
    margin-bottom: 16px;
    height: 64px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 16px;
    padding: 16px;
    .ant-card-body {
      padding: 0px;
    }
    .mainTitle {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
      .icon_grey_btn {
        .ant-btn-icon {
          .anticon {
            svg {
              path {
                fill: #0e010f;
              }
            }
          }
        }
      }
      h3 {
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
      }
    }
  }
  .fp_layout_content {
    min-height: calc(100vh - 128px);
    .cardContent {
      max-width: 562px;
      margin: 0 auto;
      margin-top: 48px;
      border: 1px solid #eff0f5;
      padding: 24px;
      border-radius: 16px;
      .ant-form {
        h4 {
          font-size: 16px;
          line-height: 22px;
          font-weight: 500;
          font-family: "DM Sans";
          margin-bottom: 4px;
        }
      }
    }
    .fp_footer {
      display: flex;
      border-top: 1px solid #ecedf3;
      width: 100%;
      padding-top: 24px;
    }
  }
}

.fp_layout_content {
  .mainTitle {
    h3 {
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      margin-bottom: 32px;
    }
  }
}

.upload_btn {
  .ant-btn {
    border-radius: 8px;
    background-color: #f3f4f8;
    border: 1px solid #f3f4f8;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #161616 !important;
  }
}

.radioTags {
  display: flex;
  align-items: center;
  gap: 13px;
  .ant-form-item {
    margin-bottom: 0px;
    display: flex;
    flex-direction: row;
    width: 100%;
    .ant-radio-group {
      display: flex;
      gap: 6px;
      .ant-radio-button-wrapper {
        border-radius: 8px;
        font-weight: 500;
        font-size: 14px;
        border-inline-start-width: 1px;
        &:hover {
          color: #161616;
        }
        &::before {
          visibility: hidden;
        }
      }
      .ant-radio-button-wrapper-checked {
        background-color: #161616;
        border: 1px solid #161616;
        color: #fff;
        font-weight: 500;
        font-size: 14px;
        &:hover {
          color: #fff;
        }
      }
    }
    .ant-row {
      display: flex;
      gap: 5px;
    }
    .ant-form-item-row {
      flex-direction: row !important;
      align-items: center;
      width: 100%;
      .ant-form-item-control {
        width: auto;
      }
      .ant-form-item-label {
        padding-bottom: 0px;
      }
    }
  }
}

.Add_Departments {
  .enterNewCompany {
    padding: 5px 15px;
    background-color: #F3F4F8;
    border-radius: 8px;
    margin-bottom: 4px;
    text-transform: inherit;
    height: 43px;
    border: unset;
    font-size: 14px;
    padding-bottom: 0px;
  }
  .actionBar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-bottom: 12px;
    .addNewItem {
      height: 40px;
      border-radius: 10px;
      .anticon {
        svg {
          path {
            fill: #161616;
          }
        }
      }
    }
    .search_departments {
      border-radius: 8px;
      height: 40px;
    }
  }
  .departments_list {
    border-radius: 12px;
    padding: 16px;
    border: 1px solid #eff0f5;
    overflow-y: auto;
    max-height: 280px;

    .ant-list-empty-text {
      color: #161616;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      padding: 0;
    }

    .ant-list-items {
      .ant-list-item {
        padding: 5px 15px;
        background-color: #f7f8fa;
        border-radius: 8px;
        margin-bottom: 4px;
        text-transform: capitalize;
        border: 0;
        &:hover {
          background-color: #f3f4f8;
        }
        .ant-list-item-action {
          li {
            padding: 0px;
            .deleteBtn {
              width: auto;
              .ant-btn-icon {
                svg {
                  path {
                    fill: #7c7e8c;
                  }
                }
              }
            }
          }
        }
      }
    }

    /* scrollbar */
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: #fff;
      border-radius: 10px;
      border-radius: 30px;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      background: #ecedf3;
      -webkit-box-shadow: #ecedf3;
      border-radius: 30px;
    }

    &::-webkit-scrollbar-thumb:window-inactive {
      background-color: #ecedf3;
    }
  }
}

.actionButton {
  border: unset;
  background-color: transparent;
  box-shadow: unset;
  // padding-right: 0px;
  &:hover {
    background-color: transparent !important;
  }
}

.edit_dropdown{
  border: unset;
  background-color: transparent;
  box-shadow: unset;
  height: 40px;
  width: 40px;
  &:hover {
    background-color: #E4E6EE !important;
  }
}

.ft_edit{
  button{
    border: unset;
    background-color: transparent;
    box-shadow: unset;
    &:hover {
      background-color: #E4E6EE !important;
    }
  }
}

.companyInfo {
  .headingH2 {
    .ant-card-meta-detail {
      .ant-card-meta-title {
        font-family: "ESRebondGrotesque-Semibold";
      }
    }
  }
}

.avatarGroup {
  .ant-avatar {
    border: 1px solid #e8e9f0;
    background-color: #f3f4f8;
    color: #161616 !important;
    width: 28px;
    height: 28px;
    font-size: 11px;
    font-weight: 600;
    line-height: normal;
    img {
      width: 28px;
      height: 28px;
    }
  }
}

.fp_layout_inner {
  .cardContent {
    h4 {
      color: #0d1019;
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;
      font-family: "ESRebondGrotesque-Semibold";
    }
  }
}

.noBorder {
  border: unset;
  background-color: transparent !important;
  box-shadow: unset;
  color: #1c1c1c !important;
  &:hover {
    background-color: transparent !important;
    border: unset;
    box-shadow: unset;
    color: #1c1c1c !important;
  }
}
.ant-form-item {
  margin-bottom: 20px;
}
.fp_select {
  // height: 40px;
  width: 100%;
  margin-bottom: 16px;
  .ant-select-selector {
    border: 1px solid #e8e9f0 !important;
    border-radius: 8px;
    min-height: 40px !important;
  }
}

.quill {
  display: flex;
  width: 100%;
  flex-direction: column;
  min-height: 124px;
  .ql-toolbar.ql-snow {
    border: 1px solid #e8e9f0 !important;
    border-bottom: unset !important;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    .ql-formats{
      &:nth-child(1){
      display: none;
      }
    }
  }
  .ql-container.ql-snow {
    border: 1px solid #e8e9f0 !important;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    .ql-editor {
      min-height: 124px;
      max-height: 200px;
      overflow-y: auto;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: #161616;
      font-family: 'DM Sans';
      p{
        a{
          color: #6661EA;
          &:hover{
            color: #9692F2;
          }
        }
      }
      a{
        color: #6661EA;
        &:hover{
          color: #9692F2;
        }
      }
    }
  }
}

.ant-select-multiple {
  .ant-select-selection-item {
    background: #eff0f5 !important;
    gap: 4px;
    display: flex;
    align-items: center;
  }
  .ant-select-selector {
    padding-inline-start: 8px;
  }
}

//// My Job css

.my_job {
  border-radius: 20px;
  .my_job_summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    h3 {
      font-size: 24px;
      font-weight: 600;
    }
    .toggle_info {
      background-color: #f3f4f8;
      border-radius: 8px;
      height: 32px;
      border-color: #f3f4f8;
      font-size: 14px;
      font-weight: 500;
      color: #161616;
      &:hover {
        background-color: #e4e6ee;
        border: 1px solid #e4e6ee;
        color: #2b2b2c;
      }
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }
    }
  }
  .job_summary_thumbs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    h4 {
      font-size: 18px;
      line-height: 22px;
      font-weight: 500;
      color: #161616;
      margin-bottom: 8px;
    }
    h5 {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: #161616;
      margin-bottom: 2px;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: #161616;
      max-width: 80%;
    }
    ul {
      padding-left: 17px;
      li {
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
        color: #161616;
        max-width: 80%;
      }
    }
    ol {
      padding-left: 17px;
      li {
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
        color: #161616;
        max-width: 80%;
      }
    }
    .show_full_info {
      .full_info_wrap {
        margin-bottom: 24px;
        width: 484px;
      }
    }
  }
}

.large_tags {
  .ant-tag {
    margin-bottom: 4px;
    margin-right: 4px;
    border-radius: 8px;
    border: 1px solid #eff0f5;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    padding: 4px 12px;
  }
}

.small_tags {
  .ant-tag {
    margin-bottom: 4px;
    margin-right: 4px;
    border-radius: 8px;
    border: 1px solid #eff0f5;
    background-color: #fff;
    font-size: 14px;
    font-weight: 300;
    padding: 2px 8px;
    color: #161616;
  }
}

.mb-20 {
  margin-bottom: 20px;
}

.job_roles_tabs {
  position: relative;
  > .ant-tabs-nav {
    .ant-tabs-nav-wrap {
      display: flex;
      justify-content: flex-end;
      .ant-tabs-nav-list {
        display: flex;
        gap: 6px;
        .ant-tabs-tab {
          background-color: #fff;
          border-radius: 8px;
          height: 32px;
          padding-left: 12px;
          padding-right: 12px;
          font-size: 14px;
          font-weight: 500;
          color: #4c4d51;
          border: 1px solid #e8e9f0;
          margin-left: 0px;
          line-height: normal;
          .ant-tabs-tab-btn {
            color: #4c4d51;
            text-shadow: unset;
            svg {
              path {
                fill: #9396a6;
              }
            }
          }
        }
        .ant-tabs-tab-active {
          background-color: #161616;
          border-radius: 8px;
          height: 32px;
          padding-left: 12px;
          padding-right: 12px;
          font-size: 14px;
          font-weight: 500;
          color: #fff;
          border: 1px solid #161616;
          .ant-tabs-tab-btn {
            color: #fff;
            text-shadow: unset;
            svg {
              path {
                fill: #fff;
              }
            }
          }
        }
        .ant-tabs-ink-bar-animated {
          display: none;
        }
      }
    }
    &::before {
      content: unset;
    }
  }
}

.darkIcon {
  svg {
    path {
      fill: #000;
    }
  }
}

// Notification

.notofications_menu {
  min-width: 400px;
  height: 340px;
  overflow-y: auto;
  background-color: #fff;
  position: absolute;
  border-radius: 15px;
  right: -140px;
  top: 5px;
  box-shadow: 0 3px 13px -12px;
  .notification_titlebar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px 22px 24px;
    .read_all_btn {
      background-color: #fff;
      color: #161616;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
    }
    h4 {
      color: #070707;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .ant-list {
    padding-left: 24px;
    padding-right: 24px;
    .ant-list-item {
      padding-top: 20px;
      padding-bottom: 20px;
      border-top: 1px solid #f3f4f8;
      border-bottom: unset;
    }
  }
  .notification_close {
    cursor: pointer;
    svg {
      path {
        fill: #161616;
      }
    }
  }
}

.emp_profile {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .emp_info {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    .ant-card-meta {
      display: flex;
      gap: 16px;
      .ant-card-meta-title {
        font-weight: 600;
        color: #161616;
      }
      .ant-card-meta-description {
        font-weight: 500;
        color: #4c4d51;
      }
    }
  }
  .right_top_job {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}

.employeeDetail {
  .jobTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      font-size: 24px;
      font-weight: 600;
      color: #161616;
    }
  }
  .roles_section {
    border: 1px solid #ecedf3;
    border-radius: 16px;
    padding: 24px !important;
    width: 1040px;
    h4 {
      font-size: 20px;
      font-weight: 600;
      color: #161616;
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      gap: 8px;
      .count_badge {
        background-color: #ecedf3;
        border-radius: 6px;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        color: #070707;
      }
    }
    .roleAdd {
      ul {
        list-style-type: none;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;

        li {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 600;
          color: #161616;
          gap: 12px;
          border: 1px solid #eff0f5;
          padding: 10px;
          border-radius: 10px;
          width: 100%;
          .count {
            font-size: 18px;
            font-weight: 500;
            color: #fff;
            border-radius: 5px;
            height: 26px;
            width: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .count-1 {
            background-color: #6661ea;
          }
          .count-2 {
            background-color: rgba(200, 71, 16, 1);
          }
          .count-3 {
            background-color: rgba(203, 44, 139, 1);
          }
          .count-4 {
            background-color: rgba(133, 88, 234, 1);
          }
          .count-5 {
            background-color: rgba(4, 126, 130, 1);
          }
          .count-6 {
            background-color: rgba(68, 128, 9, 1);
          }
          .count-7 {
            background-color: rgba(165, 97, 16, 1);
          }
          .count-8 {
            background-color: rgba(211, 51, 51, 1);
          }
          .count-9 {
            background-color: rgba(194, 37, 181, 1);
          }
          .count-10 {
            background-color: rgba(102, 97, 234, 1);
          }
          .count-11 {
            background-color: rgba(6, 131, 68, 1);
          }
          .count-12 {
            background-color: rgba(127, 117, 6, 1);
          }
          .count-13 {
            background-color: rgba(215, 52, 102, 1);
          }
          .count-14 {
            background-color: rgba(166, 60, 220, 1);
          }
          .count-15 {
            background-color: rgba(60, 104, 220, 1);
          }
          .count-16 {
            background-color: rgba(9, 132, 19, 1);
          }
          .count-17 {
            background-color: rgba(152, 103, 7, 1);
          }
          .count-18 {
            background-color: rgba(60, 104, 220, 1);
          }
          .count-19 {
            background-color: rgba(200, 71, 16, 1);
          }
          .count-20 {
            background-color: rgba(203, 44, 139, 1);
          }
          .count-21 {
            background-color: rgba(133, 88, 234, 1);
          }
          .count-22 {
            background-color: rgba(211, 51, 51, 1);
          }
          .count-23 {
            background-color: rgba(194, 37, 181, 1);
          }
          .count-24 {
            background-color: rgba(6, 131, 68, 1);
          }
        }
      }
    }
    .emptyListUi{
      height: calc(100vh - 420px);
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      img{
        margin-bottom: 16px;
      }
      h5{
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
        color: #050622;
        margin-bottom: 8px;
      }
      p{
        margin-bottom: 0px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
        color: #050622;
      }
    }
  }
  .info_section {
    width: 336px;
    ul {
      list-style-type: none;
      li {
        margin-bottom: 16px;
        background-color: #f7f8fa;
        border-radius: 10px;
        padding: 10px 12px;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      .card_role {
        .role_title {
          margin-bottom: 0px;
        }
        .role_name {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}

.ant-breadcrumb {
  display: flex;
  align-items: center;

  .ant-breadcrumb-link {
    cursor: pointer;
  }
  ol {
    width: 100%;
    li {
      .ant-breadcrumb-link {
        display: flex;
        align-items: center;
        gap: 8px;
        line-height: 0px;
        &:hover {
          background-color: transparent;
        }
      }
      span {
        font-weight: 500;
        color: #050622;
      }
    }
    .ant-breadcrumb-separator {
      display: flex;
      align-items: center;
    }
  }
}

.companyDeleteBtn {
  border: unset;
  background-color: #f7f8fa;
  box-shadow: unset;
  height: 40px;
  width: 40px;
  // padding: 10px 20px;
  &:hover {
    background-color: #E4E6EE !important;
  }
}

.no_border {
  width: 100%;
  padding: 0;
  border: 0;
  height: auto;
  box-shadow: unset;

  &:hover {
    background-color: transparent !important;
    color: inherit !important;
  }
}

.disabledTextEditor {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.25);
}

.ant-select-multiple {
  .ant-select-selection-search {
    margin-inline-start: 2px;
  }
}

.company_settings_tabs {
  .ant-tabs-tab-disabled {
    .ant-tabs-tab-btn {
      font-weight: 600;
    }
  }
}

.singleTabItem {
  .icon_dark_btn {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
}

.job_roles_settings {
  .ant-row {
    height: 100%;
    .company_settings_tabs {
      height: calc(100vh - 128px);
      .ant-tabs-content {
        height: 100%;
        .ant-tabs-tabpane {
          height: 100%;
          .fp_layout_inner {
            height: auto;
            min-height: calc(100vh - 128px);
          }
          .ant-spin-nested-loading {
            height: 100%;
            .ant-spin-container {
              height: 100%;
            }
          }
        }
      }
    }
  }
}

.addDepartmentBtn {
  width: 14px;
  height: 14px;
  padding: 0px;
  margin-bottom: 0px;
  background-color: #F3F4F8;
  border: 1px solid #F3F4F8;
  color: #070707;
  border-radius: 8px;
  box-shadow: unset;
  &:hover {
    background-color: #F3F4F8 !important;
    border: 1px solid #F3F4F8 !important;
    color: #070707;
  }
  svg {
    path {
      fill: #070707;
    }
  }
  .cancelItem{
    svg{
      height: 14px;
      width: 14px;
      path{
        fill: #7C7E8C;
      }
    }
  }
  .AddingNewItem{
    svg{
      path{
        fill: #068344;
      }
    }
  }
}

.length_tag {
  height: 32px;
  width: 32px;
  background-color: #ecedf3;
  color: #070707;
  font-size: 18px;
  font-weight: 500;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-tabs {
  .ant-tabs-tab-btn {
    .ant-tabs-tab-icon {
      margin-inline-end: 7px !important;
    }
  }
}

.warning_msg {
  background-color: #f1f0fd;
  border: 1px solid #f1f0fd;
  margin-bottom: 20px;
  svg {
    path {
      fill: #6661ea;
      width: 18px;
      height: 18px;
    }
  }
}

.ant-dropdown-menu-submenu {
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #161616;
  }

  .ant-dropdown-menu-vertical {
    max-height: 400px !important;
    overflow-y: auto !important;
    padding: 8px 4px;
    border-radius: 12px;

    .ant-dropdown-menu-item {
      box-shadow: unset;
      padding: 0px;
      margin-bottom: 4px;

      .ant-dropdown-menu-title-content {
        button {
          border: unset;
          box-shadow: unset;
          padding: 6px 12px;
          height: 36px;
          color: #070707;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;

          &:hover {
            background-color: rgba(0, 0, 0, 0.04);
          }
        }
      }
      &:hover {
        background-color: #fff;
        box-shadow: unset;
      }

      &:nth-child(1) {
        .role_desc_count {
          background-color: #3c68dc;
        }
      }
      &:nth-child(2) {
        .role_desc_count {
          background-color: #c84710;
        }
      }
      &:nth-child(3) {
        .role_desc_count {
          background-color: #cb2c8b;
        }
      }
      &:nth-child(4) {
        .role_desc_count {
          background-color: #8558ea;
        }
      }
      &:nth-child(5) {
        .role_desc_count {
          background-color: #047e82;
        }
      }
      &:nth-child(6) {
        .role_desc_count {
          background-color: #448009;
        }
      }
      &:nth-child(7) {
        .role_desc_count {
          background-color: #a56110;
        }
      }
      &:nth-child(8) {
        .role_desc_count {
          background-color: #d33333;
        }
      }
      &:nth-child(9) {
        .role_desc_count {
          background-color: #c225b5;
        }
      }
      &:nth-child(10) {
        .role_desc_count {
          background-color: #6661ea;
        }
      }
      &:nth-child(11) {
        .role_desc_count {
          background-color: #068344;
        }
      }
      &:nth-child(12) {
        .role_desc_count {
          background-color: #7f7506;
        }
      }
      &:nth-child(13) {
        .role_desc_count {
          background-color: #d73466;
        }
      }
      &:nth-child(14) {
        .role_desc_count {
          background-color: #a63cdc;
        }
      }
      &:nth-child(15) {
        .role_desc_count {
          background-color: #3c68dc;
        }
      }
      &:nth-child(16) {
        .role_desc_count {
          background-color: #098413;
        }
      }
      &:nth-child(17) {
        .role_desc_count {
          background-color: #986707;
        }
      }
      &:nth-child(18) {
        .role_desc_count {
          background-color: #3c68dc;
        }
      }
      &:nth-child(19) {
        .role_desc_count {
          background-color: #c84710;
        }
      }
      &:nth-child(20) {
        .role_desc_count {
          background-color: #cb2c8b;
        }
      }
      .role_desc_count {
        width: 16px;
        height: 16px;
        border-radius: 5px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}

.role_dropddown_cs{
  .ant-dropdown-menu-submenu{
    .ant-dropdown-menu-submenu-title{
      .ant-dropdown-menu-title-content{
        div{
          display: flex;
          align-items: center;
        }
      }
      .ant-dropdown-menu-submenu-expand-icon{
        display: none;
      }
    }
  }
}

.ant-select-multiple,
.ant-select-single {
  min-height: 40px !important;

  .ant-select-selector {
    min-height: 40px;
  }
}

.ant-form-item-has-error .ant-form-item-margin-offset {
  margin: 0 !important;
}

.ant-form-item-explain-error {
  color: #D33333;
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
  margin-top: 4px;
}

.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
  border-color: #D33333 !important;
}

.ant-select-status-error {
  .ant-select-selector {
    border: 1px solid #D33333 !important;
  }
}

.eTableModal {
  .ant-form-item {
    margin-bottom: 16px;
  }
}

.ant-select-disabled {
  .ant-select-selector {
    background: rgba(0, 0, 0, 0.04) !important;
    color: rgba(0, 0, 0, 0.25) !important;
  }
}

.ant-form-item-has-error {
  .quill .ql-toolbar.ql-snow,
  .quill .ql-container.ql-snow {
    border-color: #D33333 !important;
  }

}



.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #F3F4F8;
  color: #070707;
  font-weight: 400;
}

.ant-select-dropdown .ant-select-item {
  color: #070707;
  font-weight: 400;
}

.ant-select-multiple {
  .ant-select-selection-item {
    border: 1px solid #EFF0F5 !important;
    background: transparent !important;
    border-radius: 8px !important;
    padding: 2px 8px;
    color: #161616 !important;
  }
  .ant-select-selection-item-remove {
    display: none;
  }
}

.link_card {
  .ant-card-body{
    padding-top: 60px;
  }
}
.copyLinkText{
  .ant-input-outlined{
    border-radius: 10px;
    font-size: 14px !important;
    color: #0F0F10 !important;
    line-height: 20px !important;
    padding: 10px 8px;
    height: 40px;
  }
  .copyLinkBtn{
    border-radius: 10px;

    &:disabled {
      background-color: #F3F4F8 ;
      border: 1px solid #F3F4F8 ;
      color: #161616 ;
    }
  }
}
.addBook_wrapper {
  position: relative;
}
.book_delete {
  display: none;
  transition: all .3s;
}
.addBook_wrapper:hover .book_delete {
  display: block;
  transition: all .3s;
}
.thankyou{
  .ant-card-body{
    height: 100%;
    .empty_card{
      height: 100%;
    }
  }
}

.ant-alert-warning .ant-alert-icon {
  color: #EB9D06;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.add_part_label{
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: #161616;
  margin-bottom: 4px;
  display: flex;
}

// .editJob {
//   .ant-tabs.ant-tabs-left {
//     .ant-tabs-nav {
//       .ant-tabs-tab-active {
//         .tab-label{
//           border: 1px solid #070707 !important;
//         }
//       }
//     }
//   }
// }

.not_applicable_label{
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;
	font-family: "DM Sans";
	margin-bottom: 5px;
	color: #161616;
	position: absolute;
	right: 10px;
	top: -2px;
}

.hint_btn{
  border: unset;
  padding: 0px;
  height: auto;
  margin-left: 4px;
  margin-bottom: 2px;
}

.confirmationText{
  font-size: 14px;
	line-height: 20px;
	font-weight: 300;
	font-family: "DM Sans";
	color: #050622;
  margin-bottom: 8px;
}

.add_employee {
  padding: 2px;
  color: #070707;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.ws_session_drawer{
  .ant-drawer-open {
    overflow: hidden;
  }
  .ant-drawer {
    overflow: hidden;
  }
}

.alert_for_session{
  margin-bottom: 0px;
  border-radius: 10px;
  align-items: flex-start;
  .anticon {
    svg{
      height: 16px;
      width: 16px;
      margin-top: 3px;  
    }
  }
  .ant-alert-message{
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #161616;
  }
  .ant-alert-close-icon{
    svg{
      width: 10px;
      height: 10px;
      path{
        fill: #161616;
      }
    }
  }
}

.how_to_complete{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .guide_btn{
    border: unset;
    box-shadow: unset;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    color: #161616;
    padding: 4px;
    gap: 0px;
    &:hover{
      color: #161616 !important;
    }
  }
}

.comp_settings_title{
  font-size: 20px !important;
  line-height: 26px !important;
  font-weight: 600 !important;
  color: #161616 !important;
  font-family: 'ESRebondGrotesque-Semibold' !important;
  margin-bottom: 0px !important;
}

.first_job_card{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 330px);
  height: auto;
  border: 1px solid #ECEDF3;
  border-radius: 16px;
  text-align: center;
  .first_job_img{
    margin-bottom: 27px;
  }
  h4{
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    color: #050622;
    font-family: 'ESRebondGrotesque-Semibold';
  }
  p{
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    color: #050622;
    font-family: 'DM Sans';
  }
  .start_fp_session_btn{
    height: 56px;
    font-size: 18px;
    font-weight: 400;
    img{
      height: 28px;
      width: 28px;
    }
  }
}

.pdr_0{
  padding-right: 0px;
}

.role_job_list{
  .ant-tabs-nav-list{
    .ant-tabs-tab-active{
      height: 40px !important;
      border: 1px solid #070707 !important;
      &:hover{
        border: 1px solid #070707 !important;
      }
      .tab-label{
        border: unset !important;
      }
    }
    .ant-tabs-tab{
      height: 40px;
      transition: all .2s;
      &:hover{
        border: 1px solid #dddee1;
        transition: all .2s;
      }
      .tab-label{
        border: unset !important;
        height: 40px;
      }
    }
    .ant-tabs-tab-disabled{
      &:hover{
        border: unset;
      }
    }
  }
}

.emp_edit_dropdown{
  padding: 0px;
  height: 32px;
  width: 32px;
  border: unset;
  background-color: transparent;
  box-shadow: unset;
  &:hover{
    background-color: #E4E6EE !important;
  }
}