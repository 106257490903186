.flower_wrapper {
  width: 100%;
  background-color: #f7f8fa;
  border-radius: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-height: 644px;
}

.flower {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 644px;
  height: 614px;
  position: relative;

  .active {
    .leaf_text {
      color: #fff;
    }
    .leaf_number {
      color: #070707;
    }

    &.leaf-1 {
      .petal_svg {
        color: #6661ea;
      }
    }
    &.leaf-2 {
      .petal_svg {
        color: rgba(200, 71, 16, 1);
      }
    }
    &.leaf-3 {
      .petal_svg {
        color: rgba(203, 44, 139, 1);
      }
    }
    &.leaf-4 {
      .petal_svg {
        color: rgba(133, 88, 234, 1);
      }
    }
    &.leaf-5 {
      .petal_svg {
        color: rgba(4, 126, 130, 1);
      }
    }
    &.leaf-6 {
      .petal_svg {
        color: rgba(68, 128, 9, 1);
      }
    }
    &.leaf-7 {
      .petal_svg {
        color: rgba(165, 97, 16, 1);
      }
    }
    &.leaf-8 {
      .petal_svg {
        color: rgba(211, 51, 51, 1);
      }
    }
    &.leaf-9 {
      .petal_svg {
        color: rgba(194, 37, 181, 1);
      }
    }
    &.leaf-10 {
      .petal_svg {
        color: rgba(102, 97, 234, 1);
      }
    }
    &.leaf-11 {
      .petal_svg {
        color: rgba(6, 131, 68, 1);
      }
    }
    &.leaf-12 {
      .petal_svg {
        color: rgba(127, 117, 6, 1);
      }
    }
    &.leaf-13 {
      .petal_svg {
        color: rgba(215, 52, 102, 1);
      }
    }
    &.leaf-14 {
      .petal_svg {
        color: rgba(166, 60, 220, 1);
      }
    }
    &.leaf-15 {
      .petal_svg {
        color: rgba(60, 104, 220, 1);
      }
    }
    &.leaf-16 {
      .petal_svg {
        color: rgba(9, 132, 19, 1);
      }
    }
    &.leaf-17 {
      .petal_svg {
        color: rgba(152, 103, 7, 1);
      }
    }
    &.leaf-18 {
      .petal_svg {
        color: rgba(60, 104, 220, 1);
      }
    }
    &.leaf-19 {
      .petal_svg {
        color: rgba(200, 71, 16, 1);
      }
    }
    &.leaf-20 {
      .petal_svg {
        color: rgba(203, 44, 139, 1);
      }
    }
    &.leaf-21 {
      .petal_svg {
        color: rgba(133, 88, 234, 1);
      }
    }
    &.leaf-22 {
      .petal_svg {
        color: rgba(211, 51, 51, 1);
      }
    }
    &.leaf-23 {
      .petal_svg {
        color: rgba(194, 37, 181, 1);
      }
    }
    &.leaf-24 {
      .petal_svg {
        color: rgba(6, 131, 68, 1);
      }
    }
  }

  .leaf {
    .petal_svg {
      color: #fff;
    }
  }

  .leaf_text {
    color: #070707;
    width: 122px;
    position: absolute;
    display: flex;
    // flex-direction: column;

    h4 {
      font-size: 12px;
      line-height: 14px;
      font-weight: 600;
      margin: 0;
    }
    p {
      font-size: 10px;
      line-height: 12px;
      font-weight: 300;
      margin: 0;
    }
  }
}

.leaf {
  position: relative;
}

.flower_position {
  width: 160px;
  height: 160px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 9;
  background-color: #161616;
  border-radius: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  
  .flew_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  h4 {
    color: #fff;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    margin-bottom: 4px; 
    width: 100%;
  }

  p {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    width: 100%;
  }
}

.leaf_number {
  width: 20px;
  height: 20px;
  background-color: #fef5f1;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c84710;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 10px;
}

.flower_tab_wrapper {
  border: 1px solid #ecedf3;
  border-radius: 20px;
  padding: 24px;
  .flower_tab_heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .inline_widgets {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 20px;
  }
  .flower_tab_para {
    width: 100%;
    word-break: break-word;
    
    .ant-card-body {
      padding: 10px 12px;
    }
    h4 {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      color: #161616;
      margin-bottom: 8px;
    }
    ol, ul{
      padding-left: 16px;
    }
  }
}

.card_role {
  border: 1px solid #f7f8fa;
  background-color: #f7f8fa;
  .role_title {
    font-size: 14px;
    font-weight: 400;
    color: #161616;
    margin-bottom: 9px;
    line-height: 18px;
    height: auto;
  }
  .role_name {
    font-size: 14px;
    font-weight: 600;
    color: #161616;
  }
}

.tag_statusBlue{
  padding: 0px;
  width: 100%;
  span{
    border: 1px solid rgba(60, 104, 220, 0.4);
    padding: 2px 8px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 300;
    background-color: #F2F6FF;
    color: #3C68DC;
  }
}

.tag_statusGreen{
  padding: 0px;
  width: 100%;
  span{
    border: 1px solid #0D9B5466;
    padding: 2px 8px;
    border-radius: 6px;
    font-size: 14px;
    color: #068344;
    font-weight: 300;
  }
}

.status_column {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 28px;
  .ant-tag{
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-badge{
      margin-top: 2px;
    }
  }
  .toggleIconStatus{
    background-color: #F3F4F8;
    height: 32px;
    margin-left: 4px;
    border-radius: 8px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover{
      background-color: #F3F4F8 !important;
    }
  }
  .statusUpdateBtn {
    height: 32px;
    width: 32px;
    background-color: #f3f4f8;
    border: 1px solid #f3f4f8;
    margin-left: 4px;
    &:hover {
      background-color: #f3f4f8;
      border: 1px solid #f3f4f8;
    }
  }

  .current_status {
    padding: 2px 13px;
    border-radius: 6px;
    width: auto;
    text-align: center;
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    line-height: 24px;
    font-size: 16px;
    text-transform: capitalize;

    &.approved {
      background-color: #effdf0;
      border-color: rgba(13, 155, 84, 0.4);
      color: #068344;
    }

    &.completed {
      background-color: #f6f3ff;
      border-color: rgba(133, 88, 234, 0.4);
      color: #8558ea;
    }

    .green_badge {
      background-color: #098413;
      width: 9px;
      height: 9px;
      border-radius: 2px;
    }

    .blue_badge {
      background-color: #8558ea;
      width: 9px;
      height: 9px;
      border-radius: 2px;
    }
  }
}

.active_state {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: #161616;
  h6 {
    background-color: #6661ea;
    color: #fff;
    height: 32px;
    width: 32px;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    margin-right: 12px;
  }
}

.roles_inner_tab {
  .ant-tabs-nav {
    .ant-tabs-ink-bar {
      display: none;
    }
    .tab-number {
      color: #fff;
      height: 20px;
      width: 20px;
      border-radius: 5px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      margin-right: 8px;
    }
    margin-bottom: 16px;
    height: 40px;
    &::before {
      content: unset;
    }
    .ant-tabs-tab {
      padding: 12px;
      border: 1px solid #e8e9f0;
      border-radius: 10px;
      margin-left: 6px;
      &:first-child {
        margin-left: 0px;
      }
      .ant-tabs-tab-btn {
        color: #4c4d51;
        font-size: 16px;
        font-weight: 500;
      }
    }
    .ant-tabs-tab-active {
      background-color: #161616;
      border: 1px solid #161616;
      .ant-tabs-tab-btn {
        color: #fff;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}

h6 {
  &.tagNumber_1 {
    background-color: #6661ea;
  }
  &.tagNumber_2 {
    background-color: rgba(200, 71, 16, 1);
  }
  &.tagNumber_3 {
    background-color: rgba(203, 44, 139, 1);
  }
  &.tagNumber_4 {
    background-color: rgba(133, 88, 234, 1);
  }
  &.tagNumber_5 {
    background-color: rgba(4, 126, 130, 1);
  }
  &.tagNumber_6 {
    background-color: rgba(68, 128, 9, 1);
  }
  &.tagNumber_7 {
    background-color: rgba(165, 97, 16, 1);
  }
  &.tagNumber_8 {
    background-color: rgba(211, 51, 51, 1);
  }
  &.tagNumber_9 {
    background-color: rgba(194, 37, 181, 1);
  }
  &.tagNumber_10 {
    background-color: rgba(102, 97, 234, 1);
  }
  &.tagNumber_11 {
    background-color: rgba(6, 131, 68, 1);
  }
  &.tagNumber_12 {
    background-color: rgba(127, 117, 6, 1);
  }
  &.tagNumber_13 {
    background-color: rgba(215, 52, 102, 1);
  }
  &.tagNumber_14 {
    background-color: rgba(166, 60, 220, 1);
  }
  &.tagNumber_15 {
    background-color: rgba(60, 104, 220, 1);
  }
  &.tagNumber_16 {
    background-color: rgba(9, 132, 19, 1);
  }
  &.tagNumber_17 {
    background-color: rgba(152, 103, 7, 1);
  }
  &.tagNumber_18 {
    background-color: rgba(60, 104, 220, 1);
  }
  &.tagNumber_19 {
    background-color: rgba(200, 71, 16, 1);
  }
  &.tagNumber_20 {
    background-color: rgba(203, 44, 139, 1);
  }
  &.tagNumber_21 {
    background-color: rgba(133, 88, 234, 1);
  }
  &.tagNumber_22 {
    background-color: rgba(211, 51, 51, 1);
  }
  &.tagNumber_23 {
    background-color: rgba(194, 37, 181, 1);
  }
  &.tagNumber_24 {
    background-color: rgba(6, 131, 68, 1);
  }
}

.roles_inner_tab {
  

  .fp-tabs-1 {
    .tab-number {
      background-color: #6661ea;
    }
  }
  .fp-tabs-2 {
    .tab-number {
      background-color: rgba(200, 71, 16, 1);
    }
  }
  .fp-tabs-3 {
    .tab-number {
      background-color: rgba(203, 44, 139, 1);
    }
  }
  .fp-tabs-4 {
    .tab-number {
      background-color: rgba(133, 88, 234, 1);
    }
  }
  .fp-tabs-5 {
    .tab-number {
      background-color: rgba(4, 126, 130, 1);
    }
  }
  .fp-tabs-6 {
    .tab-number {
      background-color: rgba(68, 128, 9, 1);
    }
  }
  .fp-tabs-7 {
    .tab-number {
      background-color: rgba(165, 97, 16, 1);
    }
  }
  .fp-tabs-8 {
    .tab-number {
      background-color: rgba(211, 51, 51, 1);
    }
  }
  .fp-tabs-9 {
    .tab-number {
      background-color: rgba(194, 37, 181, 1);
    }
  }
  .fp-tabs-10 {
    .tab-number {
      background-color: rgba(102, 97, 234, 1);
    }
  }
  .fp-tabs-11 {
    .tab-number {
      background-color: rgba(6, 131, 68, 1);
    }
  }
  .fp-tabs-12 {
    .tab-number {
      background-color: rgba(127, 117, 6, 1);
    }
  }
  .fp-tabs-13 {
    .tab-number {
      background-color: rgba(215, 52, 102, 1);
    }
  }
  .fp-tabs-14 {
    .tab-number {
      background-color: rgba(166, 60, 220, 1);
    }
  }
  .fp-tabs-15 {
    .tab-number {
      background-color: rgba(60, 104, 220, 1);
    }
  }
  .fp-tabs-16 {
    .tab-number {
      background-color: rgba(9, 132, 19, 1);
    }
  }
  .fp-tabs-17 {
    .tab-number {
      background-color: rgba(152, 103, 7, 1);
    }
  }
  .fp-tabs-18 {
    .tab-number {
      background-color: rgba(60, 104, 220, 1);
    }
  }
  .fp-tabs-19 {
    .tab-number {
      background-color: rgba(200, 71, 16, 1);
    }
  }
  .fp-tabs-20 {
    .tab-number {
      background-color: rgba(203, 44, 139, 1);
    }
  }
  .fp-tabs-21 {
    .tab-number {
      background-color: rgba(133, 88, 234, 1);
    }
  }
  .fp-tabs-22 {
    .tab-number {
      background-color: rgba(211, 51, 51, 1);
    }
  }
  .fp-tabs-23 {
    .tab-number {
      background-color: rgba(194, 37, 181, 1);
    }
  }
  .fp-tabs-24 {
    .tab-number {
      background-color: rgba(6, 131, 68, 1);
    }
  }
}

.tab-label {
  display: flex;
  align-items: center
}

.flower.flower_leaf_20 + .flower_position {
  left: -100px;
}