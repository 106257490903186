.drawerBtn{
    position: fixed;
    top: 121px;
    right: -15px;
    border-radius: 0px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: #0D9B54;
    height: 56px;
    width: 80px;
    border: unset;
    box-shadow: unset;
    transition: all 0.3s;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 99;
    &:hover{
        background-color: #0f8f4f !important;
        transition: all .3s;
    }
    img{
        width: 30px;
    }
}

.ant-drawer {
    .ant-drawer-mask{
        background-color: unset;
    }
    .ant-drawer-header{
        border-bottom: unset;
        padding: 15px 20px;
        .ant-drawer-header-title {
            display: flex;
            width: 100%;
            flex-direction: row-reverse;
            .ant-drawer-title{
                font-size: 18px;
                font-weight: 600;
                color: #070707;
            }
        }
    }
}
.ant-drawer-content-wrapper{
    box-shadow: unset !important;
    width: 440px !important;
    .guide_drawer{
        border-radius: 20px;
        height: calc(100vh - 190px) !important;
        border: 1px solid #f3f4f8;
        box-shadow: 0px 8px 24px 0px #030A190A;
        position: fixed;
        right: 24px;
        width: 440px;
        margin-top: 180px;
        .ant-drawer-body{
            padding: 8px 20px;
            &::-webkit-scrollbar {
                width: 5px;
                height: 5px;
              }
          
              &::-webkit-scrollbar-track {
                -webkit-box-shadow: #fff;
                border-radius: 0px;
                border-radius: 0px;
              }
          
              &::-webkit-scrollbar-thumb {
                -webkit-border-radius: 00px;
                background: #eff0f3;
                -webkit-box-shadow: #eff0f3;
                border-radius: 0px;
              }
        }
    }
}

.drawer_tabs{
    border-radius: 5px;
    flex-wrap: wrap;
    .ant-tabs-nav{
        width: 100% !important;
        padding: 0px !important;
        .ant-tabs-nav-list{
            flex-direction: unset;
            flex-wrap: wrap;
            gap: 6px;
            margin-bottom: 18px;
            .ant-tabs-tab{
                width: auto;
                margin-bottom: 0px !important;
                font-size: 14px;
                line-height: 18px;
                font-weight: 600;
                color: #4C4D51;
                border: 1px solid #E8E9F0;
                border-radius: 8px;
                padding: 7px 9px;
            }
            .ant-tabs-tab-active{
                background-color: #161616 !important;
                border: 1px solid #161616;
                border-radius: 8px !important;
            }
        }
    }
    .ant-tabs-content-holder{
        .ant-tabs-tabpane{
            padding-left: 0px !important;
        }
    }
    .ant-tabs-content-holder{
        .wg_title{
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 16px;
            color: #070707;
            font-weight: 600;
            font-family: 'DM Sans';
            margin-bottom: 8px;
            line-height: 20px;
        }
        .wg_caption{
            margin-bottom: 12px;
            font-size: 14px;
            line-height: 20px;
            color: #070707;
            font-weight: 300;
            font-family: 'DM Sans';
        }
        .guide_sections{
            .guide_card{
                background-color: #ECFDF4;
                border-radius: 12px;
                padding: 12px 20px;
                margin-bottom: 12px;
                h6{
                    font-size: 16px;
                    font-weight: 600;
                    color: #070707;
                    margin-bottom: 8px;
                }
                p{
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 300;
                    color: #070707;
                }
                ul{
                    padding-left: 20px;
                    li{
                        font-size: 14px;
                        line-height: 20px;
                        font-weight: 300;
                        color: #070707;
                        margin-bottom: 8px;
                    }
                }
            }
            .greenBorder{
                border-left: 4px solid #0D9B54;
            }
            .blueBorder{
                border-left: 4px solid #3C68DC;
            }
            .orangeBorder{
                border-left: 4px solid #EB9D06
            }
        }
    }
}