.job_roles_table {
  width: 100%;
  // height: calc(100vh - 24px);
  overflow: auto;
  border: 1px solid #F3F4F8;
  border-radius: 8px;

  .table_inner {
    display: inline-flex;
    flex-direction: column;
    min-width: 100%;
  }
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: #fff;
    border-radius: 30px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    background: #ECEDF3;
    -webkit-box-shadow: #ECEDF3;
    border-radius: 30px;
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background-color: #ECEDF3;
  }
}

.table_header {
  background: #fff;
  display: inline-flex;
  align-items: center;
  position: sticky; 
  top: 0;
  z-index: 9;
  background-color: #F7F8FA;
  height: 50px;

  .top_role {
    width: 160px;
    height: 50px;
    position: sticky;
    left: 0;
    z-index: 9;
    background: #F7F8FA;
    padding: 15px 12px;
    color: #070707;
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.top_role_1,
    &.top_role_2 {
      width: 240px;
    }
    &.top_role_3 {
      width: 210px;
    }
    &.top_role_4 {
      width: 200px;
    }
    &.top_role_5 {
      width: 180px;
    }

    +.header_cols {
      border-left: 1px solid #F2F3F7;
    }
  }
  .header_cols {
    width: 240px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-right: 1px solid #F2F3F7;
    padding: 15px 12px;

    &.cols_1 {
      width: calc(100% - 240px);
    }
    &.cols_2 {
      width: calc(50% - 120px);
    }
    &.cols_3 {
      width: calc(33.3% - 70px);
    }
    &.cols_4 {
      width: calc(25% - 50px);
    }
    &.cols_5 {
      width: calc(20% - 45px);
    }

    .header_label {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #16181D;
      display: flex;
      align-items: center;

      h6 {
        color: #fff;
        height: 20px;
        width: 20px;
        border-radius: 5px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 500;
        margin-left: 12px;
      }
    }
  }
}

.table_body {

  .table_rows {
    border-top: 1px solid #F2F3F7;
    display: flex;
    background: #FBFBFC;
  }

  .rows_head-sticky-container {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: #fff; 
  }

  .rows_head {
    width: 160px;
    padding: 16px;
    border-right: 1px solid #EFF0F5;
    position: sticky;
    left: 0;
    z-index: 1;
    border-left: 1px solid #EFF0F5;
    background-color: #F7F8FA;
    display: flex;
    align-items: center;

    &.rows_head_1 {
      width: 240px;
    }
    &.rows_head_2 {
      width: 240px;
    }
    &.rows_head_3 {
      width: 210px;
    }
    &.rows_head_4 {
      width: 200px;
    }
    &.rows_head_5 {
      width: 180px;
    }

    .ant-typography {
      color: #070707;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      word-break: break-word;
    }
  }

  .rows_cols {
    border-right: 1px solid #F2F3F7;
    width: 240px;
    display: flex;
    justify-content: flex-start;
    background-color: #fff;
    flex-direction: column;
    padding: 8px 12px;

    &.rows_cols_1 {
      width: calc(100% - 240px);
    }

    &.rows_cols_2 {
      width: calc(50% - 120px);
    }

    &.rows_cols_3 {
      width: calc(33.3% - 70px);
    }
    &.rows_cols_4 {
      width: calc(25% - 50px);
    }
    &.rows_cols_5 {
      width: calc(20% - 45px);
    }

    ul {
      padding-left: 16px;
    }

    .rows_para {
      color: #070707;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      word-break: break-word;
      .defaultTag{
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      ol, ul {
        padding-left: 10px;
        li {
          margin-bottom: 4px;
        }
      }
    }
    

    &:last-child {
      border-right: 0;
    }
  }
}

.department_roles {
  .roles_top {
    margin-bottom: 32px;
    h4 {
      color: #16181D;
      font-weight: 500;
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 16px;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #505560;
      margin-bottom: 0;
    }
  }

  .add_new_department {
    width: 100%;
    margin-bottom: 24px;

    button {
      background: #FBFBFC;
      border-radius: 8px;
      width: 100%;
      height: 40px;
      color: #16181D;
      font-weight: 500;
      line-height: 20px;
      padding: 10px 0;
      
      span {
        font-size: 14px !important;

      }
    }
  }

  .roles_card {
    margin-bottom: 24px;

    .ant-card {
      margin-bottom: 24px;
    }

    .ant-card {
      background: #FFFFFF;
      border: 1px solid #E4E5E7;
      border-radius: 16px;
    }

    h4 {
      color: #0B9F57;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 16px;

      + .department_row {
        position: relative;

        &.only_department {
          &:before,
          &:after {
            display: none;
          }
        }


        &:after {
          content: '';
          background: #E4E5E7;
          position: absolute;
          left: -20px;
          top: 0;
          width: 2px;
          height: 160%;
        }
        &:before {
          content: '';
          background: #E4E5E7;
          position: absolute;
          left: -20px;
          bottom: -60%;
          width: 30px;
          height: 2px;
        }

        + .roles_group_wrapper {
          position: relative;

          .subgroup {
            &:after,
            &:before {
              display: block;
            }

            &::before {
              left: -10px;
              bottom: -123%;
            }
            &::after {
              height: 220%;
              left: -10px;
            }

            &.nosubgroup {
              &:after,
              &:before {
                display: none;
              }
            }
          }

          .subgroup_wrapper {
            h4 {
              margin: 20px 0 15px 20px;
            }
            .subgroup {
              &:after,
              &:before {
                display: none;
              }
            }
            
            .subgroup_wrapper {
              h4 {
                margin: 20px 0 15px 10px;
              }
            }
          }

          &.only_roles {
            &:after,
            &:before {
              display: none;
            }
          }

          &:after {
            content: '';
            background: #E4E5E7;
            position: absolute;
            left: 5px;
            top: 10px;
            width: 2px;
            height: 100%;
          }
          &:before {
            content: '';
            background: #E4E5E7;
            position: absolute;
            left: 6px;
            bottom: -10px;
            width: 32px;
            height: 2px;
          }
        }
      }
    }

    .department_row {
      margin-left: 25px;
      margin-bottom: 24px;
      background: #FBFBFC;
      border-radius: 8px;
      padding: 12px 12px 12px 20px;
      display: flex;
      align-items: center;

      &:hover {
        .department_edit_delete {
          opacity: 1;
          visibility: visible;
        }
      }

      &.subgroup {
        margin-left: 15px;
        .department_name {
          width: calc(100% - 125px);
        }
      }

      .department_name {
        color: #16181D;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        width: calc(100% - 190px);
        display: flex;
        align-items: center;
        justify-content: center;
        
        
      }

      .role_group_btn {
        margin-left: auto;
        button {
          margin-right: 8px;
          background: #FFFFFF;
          border: 1px solid #E4E5E7 !important;
          border-radius: 44px;
          color: #16181D;
          font-size: 12px;
          line-height: 16px;
          padding: 8px 12px;
        }
      }
      .department_edit_delete {
        margin-left: auto;
        opacity: 0;
        visibility: hidden;
      }
    }

    .department_group_wrapper,
    .roles_group_wrapper {
      padding-left: 50px;

      h4 {
        + .department_row {
          &:after,
          &:before {
            display: none;
          }

          &.subgroup {
            &:after,
            &:before {
              display: block;
            }

            &::before {
              left: -10px;
              bottom: -123%;
            }
            &::after {
              height: 220%;
              left: -10px;
            }

            &.nosubgroup {
              &:after,
              &:before {
                display: none;
              }
            }
          }
        }
      }

      .subgroup_wrapper {
        h4 {
          margin: 20px 0 15px -5px;
        }

        .subgroup_wrapper {
          h4 {
            margin: 20px 0 15px 10px;
          }
        }
      }

      .department_row {
        + .role_group_table {
          margin-left: 55px;
        }
      }
    }

    .role_group_table {
      margin-left: 15px;

      &.sub_group_table {
        margin-left: 50px;
      }

      .ant-table {
        .ant-table-container {
          table {
            display: table;
            width: 100%;

            > .ant-table-tbody {
              width: 100%;
              display: block;

              > tr {
                background: #FBFBFC;
                border-radius: 8px;
                margin-bottom: 10px;
                width: 100%;
                display: block;
    
                td {
                  padding: 12px 8px;
                  width: 100%;
                  display: block;
                  border-bottom: 0;

                  &:hover {
                    .drag-inner-header {
                      .right_btn {
                        opacity: 1;
                        visibility: visible;
                      }
                    }
                  }

                  .drag-inner-header {
                    width: 100%;

                    .right_btn {
                      margin-left: auto;
                      opacity: 0;
                      visibility: hidden;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .drag-icon {
      display: none;
    }

    .role_info_wrapper {
      margin-left: 8px;
      .role_top_name {
        color: #16181D;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-transform: capitalize;
        margin-bottom: 2px;
      }

      .role_btn_name {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #505560;
        text-transform: capitalize;
      }
    }
  }
}

.add_role_to_group {
  margin-left: 24px;
  margin-top: -24px;
  
  button {
    &.ant-btn {
      &.ant-btn-link {
        padding: 8px 4px;

        svg {
          path {
            fill: #0B9F57
          }
        }

        > .anticon + span {
          margin-left: 8px;
          color: #0B9F57;
          font-weight: 500;
          font-size: 14px !important;
          line-height: 20px;
        }
        
      }
    }
  }
}

.subgroup_wrapper_main {
  margin-left: 50px !important;
}

.main_department {
  position: relative;

  &:before {
    content: "";
    background: #E4E5E7;
    position: absolute;
    left: 8px;
    bottom: -32px;
    width: 30px;
    height: 2px;
  }
  
  &:after {
    content: "";
    background: #E4E5E7;
    position: absolute;
    left: 8px;
    top: 0;
    width: 2px;
    height: calc(100% + 32px);
  }

  // &.no_roles_department {
  //   &:after {
  //     height: 135%;
  //   }
  //   &:before {
  //     bottom: -34%;
  //   }
  // }
}

.sub_department {
  position: relative;

  &:after,
  &:before {
    display: none;
  }

  &.without_subgroup {
    &:after,
    &:before {
      display: none;
    }
  }

  // &:before {
  //   content: "";
  //   background: #E4E5E7;
  //   position: absolute;
  //   left: 8px;
  //   bottom: -32px;
  //   width: 30px;
  //   height: 2px;
  // }
  
  // &:after {
  //   content: "";
  //   background: #E4E5E7;
  //   position: absolute;
  //   left: 8px;
  //   top: 0;
  //   width: 2px;
  //   height: calc(100% + 32px);
  // }

  // &.no_roles_department {
  //   &:after {
  //     height: 135%;
  //   }
  //   &:before {
  //     bottom: -34%;
  //   }
  // }
}

.only_department {
  &:before,
  &:after {
    display: none;
  }

  // .main_department {
  //   &::after,
  //   &::before {
  //     display: none;
  //   }
  // }
}


.coach_text{
  font-weight: 600;
  font-size: 14px;
  color: #161616;
  margin-bottom: 7px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.location_table_wrapper {
  display: flex;
  align-items: center;
}

.tooltip_location {
  display: flex;
  align-items: center;
  padding: 2px 8px;
  font-size: 14px;
  font-weight: 300;
  color: #161616;
  background-color: #F7F8FA;
  margin-left: 4px;
}